.checklist {
  /* margin: 200px;
  width: 100%; */

  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  margin-left: 50px;
}

h1 {
  font-size: 1.5rem;
}

ul {
  list-style-type: decimal;
}

::marker {
  background-color: #48bb78;
  /* color: white; */
}

ul li {
  font-size: 1rem;
  line-height: 1.8;
  margin-top: 1rem;
}

.form {
  margin: 20px 10px;
}

.form a {
  padding: 10px;
  background-color: #48bb78;
  color: white;
  border-radius: 10px;
  margin-right: 10px;
  font-weight: bold;
}

a {
  font-weight: bold;
}

span {
  padding-left: 5px;
}

@media screen and (max-width: 900px) {
  .checklist {
    margin: 80px;
    width: 80%;
  }

  h1 {
    font-size: 1rem;
  }
}
