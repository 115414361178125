@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Montserrat:wght@200;300;400;500;600&display=swap');

@font-face {
  font-family: "Jakarta";
  src: url("/font/PlusJakartaDisplay-Regular.otf");
  font-weight: normal;
  font-style: normal;
}

body,
html {
  font-family: "Jakarta" !important;
}

/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
  transition: transform 300ms ease-in-out;
  transition-delay: 100ms;
  transform: scale(0);
  /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open {
  transform: scale(1);
  /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close {
  transform: scale(0);
  /* transform: translateY(-150%); */
}
#new-rate {
  color: black;
  font-size: 24px;
}

/* body {
  font-family: "Montserrat", sans-serif;
} */

input {
  max-width: none !important;
}

.swal2-cancel {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 100% !important;
  width: 40px !important;
  height: 40px !important;
  background-image: url(../images/x-circle.png) !important;
  background-position: center !important;
  background-color: white !important;
  background-repeat: no-repeat !important;
}

.swal2-confirm {
  background-color: #48bb78 !important;
}
