.file__card {
  margin: 400px;
  width: 400px;
  background-color: #edf2f7;
  border: 3px dashed #cbd5e0;
  padding: 1em;
  min-width: 400px;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
}

.file__input {
  position: relative;
  margin-bottom: 1.5em;
  background-color: red;
}

input {
  position: relative;
  max-width: 200px;
  height: 46px;
  z-index: 2;
  cursor: pointer;
}

.btn {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #f55e30;
  font-size: 1.1rem;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  outline: none;
}

.icon {
  width: 1.5em;
  height: 1.5em;
  background-color: #fff;
  color: #48bb78;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.8em;
  font-size: 0.8em;
}
