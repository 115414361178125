.error-page {
  display: flex;
  /* background-image: url("../../assets/500.svg"); */

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* align-items: center;
justify-content: center;
background-size: 50%;
background-repeat: no-repeat;
background-position: center; */
  background: rgb(248, 248, 248);
  flex-direction: column;
}
.error-image {
  height: 50%;
  background-image: url("../../images/500.svg");
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: center;
}

.error-instruction {
  text-align: center;
  height: 50%;
  color: slategray;
}

.error-header {
  font-size: 40px;
}
